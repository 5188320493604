<template>
  <div>
    <v-divider></v-divider>
    <v-layout wrap fill-height>
      <v-flex xs12 sm12 md2 lg2 class="hidden-sm-and-down">
        <v-navigation-drawer
          class="fixedSidebar"
          clipped
          width="100%"
          height="100vh"
          permanent
          style="background-color: #fafafa"
        >
          <v-layout style="background-color: #fff; height: 85px !important">
            <v-flex xs12 pl-4 align-self-center>
              <v-img contain width="100%" height="90%" src="../assets/hwclogojpg.jpg" fill-height></v-img>
              <!-- <v-toolbar-title
                class="pt-3"
                style="font-family:opensansextrabold
              letter-spacing: 0.63px;
            color: #000000;"
                >WILD WATCH</v-toolbar-title
              > -->
            </v-flex>
          </v-layout>

          <v-list dense nav>
            <v-list-item
              v-for="item in items"
              :key="item.title"
              style="letter-spacing: 0.63px"
              link
              class="pr-0"
              :to="item.path"
             
            >
              <v-list-item-icon>
                <!-- <v-icon class="pr-3" color="#9F9F9F">{{ item.icon }}</v-icon> -->
                <v-icon class="pr-3">{{ item.icon }}</v-icon>
                <v-list-item-title
                  style="
                    font-size: 14px;
                    font-family: poppinsmedium;
                  "
                  >{{ item.title }}</v-list-item-title
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md10 lg10>
        <v-layout wrap pb-0 mb-0>
          <v-flex xs12 sm12 md12 lg12 pb-0 mb-0>
            <AppHeader class="pb-0 mb-0" />
          </v-flex>
          <v-flex xs12 pt-0 mt-0
            ><v-divider class="pt-0 mt-0"></v-divider
          ></v-flex>
          <v-flex xs12 sm12 md12 lg12 pl-3>
            <v-layout
              wrap
              justify-center
              :style="
                $route.name == 'dashboard'
                  ? 'background-color: #ffffff'
                  : 'background-color: #f6fff9'
              "
            >
              <v-flex xs12>
                <v-card
                  min-height="100vh"
                  :color="
                    $route.name == 'dashboard' ? 'transparent' : '#FFFFFF'
                  "
                  flat
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12>
                      <router-view :key="$route.fullPath"></router-view>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "@/components/Common/appHeader";

export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", path: "/Officers/dashboard" },
        { title: "My Details", icon: "mdi-account", path: "/Officers/profile" },
        {
          title: "Analytics",
          icon: "mdi-home-analytics",
          path: "/Officers/analytics",
        },
        {
          title: "Report Generation",
          icon: "mdi-table",
          path: "/Officers/reportGenerationofficers",
        },
        {
          title: "Closed Cases",
          icon: "mdi-close",
          path: "/Officers/closedcases",
        },
        { title: "Notifications", icon: "mdi-bell", path: "/Officers/notifications" },
        { title: "Report Case", icon: "mdi-file-eye", path: "/Officers/reportcase" },


      ],
      right: null,
    };
  },
};
</script>